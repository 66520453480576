import React from "react";
import { useSubscription } from "@apollo/react-hooks";
import { awaitDriverLoc } from "./graphql/subscriptions";
import Direction from "./direction";

const getDriverLocation = (data, myDriverId) => {
  if (!data) return null;
  if (!data.awaitDriverLoc) return null;
  if (data.awaitDriverLoc.driverId === myDriverId) return data.awaitDriverLoc;
  else return null;
};

const TrackingContainer = React.memo(
  ({ initailOrigin, destination, driverId, ...rest }) => {
    const { data, loading, error } = useSubscription(awaitDriverLoc, {
      variables: {
        id: driverId,
      },
    });

    let origin = getDriverLocation(data, driverId) || initailOrigin;

    console.log("driver data : ", origin);
    if (!destination || !origin) return null;
    return (
      <Direction
        origin={`${origin.latitude},${origin.longitude}`}
        destination={destination}
        {...rest}
      />
    );
  }
);

export default TrackingContainer;
