import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  FacebookMessengerShareButton,
} from "react-share";

import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import EmailIcon from "@material-ui/icons/Email";
import SmsIcon from "@material-ui/icons/Sms";

const share_services = {
  email: {
    comp: EmailShareButton,
    icon: <EmailIcon style={{ color: "#F44336" }} />,
  },
  fb: { comp: FacebookShareButton },
  insta: { comp: InstapaperShareButton },
  line: { comp: LineShareButton },
  linkdin: { comp: LinkedinShareButton },
  livejournal: { comp: LivejournalShareButton },
  mailru: { comp: MailruShareButton },
  okshare: { comp: OKShareButton, icon: SmsIcon },
  pinterest: { comp: PinterestShareButton },
  pocketshare: { comp: PocketShareButton },
  reddit: { comp: RedditShareButton },
  telegram: { comp: TelegramShareButton },
  tumblr: { comp: TumblrShareButton },
  twitter: { comp: TwitterShareButton },
  viber: { comp: ViberShareButton },
  vk: { comp: VKShareButton },
  whatsapp: {
    comp: WhatsappShareButton,
    icon: <WhatsAppIcon style={{ color: "#44e761" }} />,
  },
  workplace: { comp: WorkplaceShareButton },
  fbmsg: {
    comp: FacebookMessengerShareButton,
    icon: <FacebookIcon style={{ color: "#3F51B5" }} />,
  },
};
const getLink = () => {
  if (typeof window !== "undefined") {
    return window.location.href;
  } else return null;
};

const renderService = (key) => {
  const Service = share_services[key].comp;
  const icon = share_services[key].icon;
  return (
    <Service url={getLink()} size={32} round={true}>
      {icon}
    </Service>
  );
};
export default ({ services }) => {
  return services.map(renderService);
};
