import gql from "graphql-tag";

export const awaitDriverLoc = gql`
  subscription($id: ID!) {
    awaitDriverLoc(id: $id) {
      latitude
      longitude
      driverId
    }
  }
`;

export const awaitStartRide = gql`
  subscription($id: ID!) {
    awaitRideStatus(rideId: $id) {
      status
      rideId
    }
  }
`;
