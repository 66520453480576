import React, { useState } from "react";
import { isBrowser } from "../../utils/general";

export default (props) => (
  <div className="timer">
    <div style={{ fontSize: 22 }}>
      <div>{props.value}</div>
    </div>
    <div style={{ color: "grey" }}>
      <div>{props.unit}</div>
      <div>left</div>
    </div>
  </div>
);

function DivMarker(latlng, map, value) {
  this.latlng_ = latlng;
  let s = value.split(" ");
  this.dist = s[0];
  this.unit = s[1];
  /*Do this or nothing will happen:*/
  this.setMap(map);
}

DivMarker.prototype = isBrowser() ? new google.maps.OverlayView() : {};
DivMarker.prototype.draw = function () {
  var me = this;
  var div = this.div_;
  if (!div) {
    // Create a overlay text DIV
    div = this.div_ = document.createElement("DIV");
    div.className = "timer";
    div.style.position = "absolute";
    div.style.cursor = "pointer";
    var lDivConatiner = document.createElement("DIV");
    var lDiv = (this.lDiv_ = document.createElement("DIV"));
    lDiv.innerHTML = this.dist;
    lDiv.className = "dist";
    lDivConatiner.appendChild(lDiv);

    var rDivContainer = document.createElement("DIV");
    rDivContainer.style.color = "grey";

    var uDiv = (this.uDiv_ = document.createElement("DIV"));
    uDiv.innerHTML = this.unit;
    var labelDiv = document.createElement("DIV");
    labelDiv.innerHTML = "Left";

    rDivContainer.appendChild(uDiv);
    rDivContainer.appendChild(labelDiv);

    div.appendChild(lDivConatiner);
    div.appendChild(rDivContainer);

    // Then add the overlay to the DOM
    var panes = this.getPanes();
    panes.overlayImage.appendChild(div);
  }

  // Position the overlay
  var point = this.getProjection().fromLatLngToDivPixel(this.latlng_);
  if (point) {
    div.style.left = point.x + 20 + "px";
    div.style.top = point.y + 20 + "px";
    //  div.style.margin = "20px";
  }
};

DivMarker.prototype.remove = function () {
  // Check if the overlay was on the map and needs to be removed.
  if (this.div_) {
    this.div_.parentNode.removeChild(this.div_);
    this.div_ = null;
  }
};

DivMarker.prototype.getPosition = function () {
  return this.latlng_;
};

DivMarker.prototype.changePosition = function (latlng_, value) {
  this.latlng_ = latlng_;
  var point = this.getProjection().fromLatLngToDivPixel(this.latlng_);
  if (point) {
    this.div_.style.left = point.x + "px";
    this.div_.style.top = point.y + "px";
    let s = value.split(" ");
    this.dist = s[0];
    this.unit = s[1];
    this.lDiv_.innerHTML = s[0];
    this.uDiv_.innerHTML = s[1];
  }
};

export const Overlay = DivMarker;
