import React, { useState } from "react";
import { Row, Col, Collapse, Button } from "reactstrap";
import "./index.css";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CANCEL_RIDE, CHANGE_DATE_TIME } from "./graphql/mutation";
import { useMutation } from "@apollo/react-hooks";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CallIcon from "@material-ui/icons/Call";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import { useMediaQuery } from "react-responsive";
import ShareService from "./share-link";

import { DateTimePicker } from "../DateTimePicker";

var localization = require("moment/locale/de");

const timestampToDate = (timestamp) => {
  if (!timestamp) return null;
  return new Date(Number(timestamp));
};

const canUpdate = (rideData, pTime) => {
  let ride_now = rideData.rideType === "ride_now";
  let t = ride_now ? 5 : 60;
  let p = moment(pTime);
  let now = moment();
  let diff = ride_now ? now.diff(p, "minutes") : p.diff(now, "minutes");
  if ((ride_now && diff >= t) || (!ride_now && diff <= t)) {
    alert(
      ride_now
        ? "Ride can not be updated anymore. Our driver is alredy on its way."
        : "Ride can not be updated 1 hour before scheduled time"
    );
    return false;
  } else {
    return true;
  }
};

const DateTimeChanger = ({
  defaultDate,
  toggleTimeChanger,
  proceedDateTime,
  loading,
  labels,
}) => {
  const [date, setDate] = useState(timestampToDate(defaultDate) || new Date());
  const apply = () => {
    proceedDateTime(date);
  };

  return (
    <div className="time-changer">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {labels.dateTimeChange}
        <IconButton onClick={toggleTimeChanger}>
          <CloseIcon />
        </IconButton>
      </div>
      <div style={{ position: "relative" }}>
        <DateTimePicker
          onChange={setDate}
          culture="de"
          defaultDate={timestampToDate(defaultDate)}
        />
      </div>
      <br />
      <Button className="cancel-button" onClick={apply} disabled={loading}>
        <div style={{ fontSize: 14, fontWeight: "500" }}>{labels.proceed}</div>
      </Button>
      <br /> <br />
    </div>
  );
};

const Field = ({ Icon, iconStyle, label, value }) => (
  <Row style={{ marginTop: 10 }}>
    <Col lg={4} xs={4} style={{ display: "flex", alignItems: "center" }}>
      {Icon ? (
        <Icon style={{ fontSize: 14, marginTop: 2, ...iconStyle }} />
      ) : null}
      &nbsp;
      <div className="value-text">{label}</div>
    </Col>
    <Col className="value-text">{value}</Col>
  </Row>
);

export default React.memo(({ rideData, labels, status }) => {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
  const [fullInfo, setFullInfo] = useState(isDesktopOrLaptop);
  const [timeChanger, setTimeChanger] = useState(false);
  const [pTime, setPTime] = useState(timestampToDate(rideData.pickupDate));
  const [
    cancelRide,
    { data: cancelResp, loading: cancelLoad, error: cancelError },
  ] = useMutation(CANCEL_RIDE);
  const [
    changeDateTime,
    { data: dtResp, loading: dtLoad, error: dtError },
  ] = useMutation(CHANGE_DATE_TIME);

  const toggle = () => {
    setFullInfo(!fullInfo);
  };

  const toggleTimeChanger = () => {
    if (status === "picked") {
      return alert("Ride cannot be updated");
    }

    if (
      !canUpdate(
        rideData,
        timestampToDate(
          rideData.rideType === "ride_now" ? rideData.paymentTime : pTime
        )
      )
    )
      return setTimeChanger(false);
    else setTimeChanger(!timeChanger);
  };

  const driver = {
    firstName: "John",
    lastName: "Doe",
  };

  const doCancel = async () => {
    if (status === "picked") {
      return alert("Ride cannot be cancelled");
    }

    if (
      !canUpdate(
        rideData,
        timestampToDate(
          rideData.rideType === "ride_now" ? rideData.paymentTime : pTime
        )
      )
    )
      return;
    console.log("rideData: ", rideData);
    let r = confirm(labels.cancelAlert);
    if (r) {
      let resp = await cancelRide({
        variables: {
          id: rideData.id,
        },
      });
      if (resp && resp.data) {
        window.location.reload();
        console.log("ride has been cancelled...", cancelResp);
      }
    }
  };

  const proceedDateTime = async (pickupDate) => {
    changeDateTime({
      variables: {
        pickupDate,
        pickupDateForText: moment(pickupDate)
          .locale("de", localization)
          .format("dddd, Do MMMM YYYY, HH:mm"),
        id: rideData.id,
      },
    })
      .then((res) => {
        alert("Date time changed successfully");
        setPTime(pickupDate);
        toggleTimeChanger();
      })
      .catch((error, abc) => {
        alert("Pickup time cannot be changed more than 2 times");
      });
  };

  return (
    <div className="ride-info-cotainer">
      <div style={{ background: "white" }}>
        <div className="top-info">
          <img
            src={rideData.driver.profilePic || require("./images/man.png")}
            style={{ width: 50, height: 50, borderRadius: 30 }}
          />
          &nbsp; &nbsp;
          <div>
            <h5>{`${rideData.driver.firstName} ${rideData.driver.lastName}`}</h5>
            <p style={{ display: "flex", alignItems: "center" }}>
              <CallIcon style={{ color: "#8BC34A", fontSize: 14 }} />
              &nbsp;
              <a
                href={`tel:${rideData.driver.phone}`}
                style={{ color: "black", fontWeight: "bold" }}
              >
                {rideData.driver.phone}
              </a>
            </p>
          </div>
          <div onClick={toggle}>
            <ExpandMoreIcon
              style={{
                top: 15,
                position: "absolute",
                right: 15,
                transition: "0.2s all",
                transform: "rotate(" + (!fullInfo ? 180 : 0) + "deg)",
              }}
            />
          </div>
        </div>
        <div className="share-btn">
          Share This Tracking Link
          <ShareService services={["whatsapp", "fbmsg", "email"]} />
        </div>
        <Collapse isOpen={fullInfo}>
          <div>
            <Field
              Icon={LocationOnIcon}
              label={labels.pickUp}
              value={rideData.pickupLoc}
              iconStyle={{
                color: "#ee5b24",
              }}
            />
            <Field
              Icon={LocationOnIcon}
              label={labels.drop}
              value={rideData.destLoc}
              iconStyle={{
                color: "#fdae31",
              }}
            />
            <Field
              Icon={CreditCardIcon}
              label={labels.payment}
              value={`€ ${rideData.netFare} ${rideData.paymentType}`}
              iconStyle={{
                color: "red",
              }}
            />

            {!timeChanger && rideData.status !== "cancelled" && (
              <Button
                className="cancel-button"
                style={{ position: "relative" }}
                onClick={toggleTimeChanger}
              >
                <div style={{ fontSize: 14, fontWeight: "500" }}>
                  <div>
                    {labels.pickUpDate}:{moment(pTime).format("DD-MMM HH:mm")}
                  </div>
                </div>
                <EditIcon
                  style={{
                    position: "absolute",
                    right: 5,
                    top: 3,
                  }}
                />
              </Button>
            )}

            <Collapse isOpen={timeChanger}>
              <DateTimeChanger
                toggleTimeChanger={toggleTimeChanger}
                proceedDateTime={proceedDateTime}
                loading={dtLoad}
                defaultDate={rideData.pickupDate}
                labels={labels}
              />
            </Collapse>
          </div>
        </Collapse>
        {!timeChanger && rideData.status !== "cancelled" && (
          <Button
            disabled={cancelLoad}
            className="cancel-button"
            onClick={doCancel}
          >
            <div style={{ fontSize: 14, fontWeight: "500" }}>
              {labels.cancelRide}
            </div>
          </Button>
        )}
      </div>
    </div>
  );
});
