import React, { useContext, useState } from "react";
import Tracking from "./tracking";
import { RIDE_INFO } from "./graphql/queries";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import { awaitStartRide } from "./graphql/subscriptions";
import RideInfo from "./ride-info";
import { LanguageContext } from "../../lang";

const getRideStatus = (subs, ride) => {
  if (subs && subs.awaitRideStatus) {
    if (subs.awaitRideStatus.rideId === ride.id) {
      console.log("this is my ride....");
      return subs.awaitRideStatus.status;
    }
  }
  return ride ? ride.status : null;
};

export default ({ rideId, local }) => {
  const {
    translations: { tracking },
  } = useContext(LanguageContext);

  const { loading, error, data } = useQuery(RIDE_INFO, {
    variables: {
      id: rideId,
    },
  });

  const { data: subRideData } = useSubscription(awaitStartRide, {
    variables: {
      id: rideId,
    },
  });

  let rideData = data && JSON.parse(JSON.stringify(data.ride));
  let status = getRideStatus(subRideData, rideData);

  return (
    <div>
      {loading ? (
        "Loading..."
      ) : error ? (
        "Something went wrong"
      ) : (
        <div>
          {status !== "completed" && status !== "cancelled" && (
            <RideInfo rideData={rideData} labels={tracking} status={status} />
          )}

          {status !== "started" && status !== "picked" ? (
            <>
              <div
                style={{
                  padding: 20,
                  position: "fixed",
                  top: 150,
                  left: 0,
                  right: 0,
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    background: "#ff000047",
                    border: "1px solid #eee",
                    padding: 20,
                    fontSize: 18,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {getStatusString(status, tracking)}
                </span>
              </div>
            </>
          ) : (
            <Tracking
              rideStatus={status}
              driverId={rideData.driverId}
              initailOrigin={rideData.driver}
              destination={getDestination(status, rideData)}
              local={local}
            />
          )}
        </div>
      )}
    </div>
  );
};
const getDestination = (status, rideData) => {
  if (status === "picked") {
    return `${rideData.destCoordinateY},${rideData.destCoordinateX}`;
  } else return `${rideData.pickupCoordinateY},${rideData.pickupCoordinateX}`;
};

const getStatusString = (status, labels) => {
  switch (status) {
    case "arrived":
      return labels.driverArrived;
    case "cancelled":
      return labels.rideCancelled;
    case "completed":
      return labels.rideCompleted;
    default:
      return labels.rideNotStarted;
  }
};
