var numDeltas = 100;
var delay = 10; //milliseconds
var i = 0;
var deltaLat;
var deltaLng;
var marker;
function transition(result, m, position) {
  i = 0;
  marker = m;
  deltaLat = (result[0] - position[0]) / numDeltas;
  deltaLng = (result[1] - position[1]) / numDeltas;
  moveMarker(position);
}

function moveMarker(position) {
  position[0] += deltaLat;
  position[1] += deltaLng;
  var latlng = new google.maps.LatLng(position[0], position[1]);
  marker.setPosition(latlng);
  if (i != numDeltas) {
    i++;
    setTimeout(() => {
      moveMarker(position);
    }, delay);
  }
}

export default transition;
