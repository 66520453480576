import gql from "graphql-tag";

export const RIDE_INFO = gql`
  query getRideInfo($id: String!) {
    ride(id: $id) {
      id
      pickupLoc
      destLoc
      pickupCoordinateX
      pickupCoordinateY
      destCoordinateX
      destCoordinateY
      netFare
      pickupDate
      driverId
      paymentType
      status
      rideType
      paymentTime
      driver {
        firstName
        lastName
        address
        latitude
        longitude
        phone
        profilePic
      }
    }
  }
`;

export default {
  RIDE_INFO,
};
