import React from "react";
import styles from "./style";
import TimeTracker, { Overlay } from "./distance";
import moveMarker from "./move-marker";
import user_icon from "./images/user.png";
import caculateDistance from "./calc-distance";
const car_url = require("./images/_taxi.png");
export default class extends React.PureComponent {
  state = {
    directions: null,
    destination: this.props.destination,
    origin: this.props.origin,
  };
  componentDidMount() {
    this.initMap();
  }

  componentDidUpdate(prev) {
    if (
      prev.origin !== this.props.origin ||
      prev.destination !== this.props.destination
    ) {
      this.refresh(
        this.props.origin,
        prev.destination !== this.props.destination
      );
    } else {
      console.log("same location ", this.props.origin);
    }
  }

  initMap = () => {
    const { origin, destination } = this.props;
    if (!origin || !destination) return null;

    let splt1 = origin.split(",");
    let splt2 = destination.split(",");
    const m1_lat = parseFloat(splt1[0]);
    const m1_lng = parseFloat(splt1[1]);
    const m2_lat = parseFloat(splt2[0]);
    const m2_lng = parseFloat(splt2[1]);
    var A = { lat: m1_lat, lng: m1_lng };
    var B = { lat: m2_lat, lng: m2_lng };
    var map = new google.maps.Map(document.getElementById("map"), {
      zoom: 12,
      center: A,
      styles,
    });
    this.map = map;
    this.car = new google.maps.Marker({
      position: A,
      map: map,
    });
    this.dest = new google.maps.Marker({
      position: B,
      map: map,
      icon: {
        url: user_icon,
        scale: 0.03,
      },
    });

    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer({
      map,
      suppressMarkers: true,
      polylineOptions: {
        strokeColor: "#13f883",
      },
    });
    // var bounds = new google.maps.LatLngBounds();
    // bounds.extend(this.car.getPosition());
    // this.map.fitBounds(bounds, 200);
    this.calculateAndDisplayRoute(origin, destination);
  };

  refresh = (origin, destinationUpdated) => {
    let { destination } = this.props;
    let splt0 = destination.split(",");

    let splt1 = origin.split(",");
    let p = this.car.getPosition();
    const m1_lat = p.lat();
    const m1_lng = p.lng();
    const m2_lat = parseFloat(splt1[0]);
    const m2_lng = parseFloat(splt1[1]);

    ////////////////////////////////////////////

    const d_lat = parseFloat(splt0[0]);
    const d_lng = parseFloat(splt0[1]);

    let total_distance = caculateDistance(m1_lat, m1_lng, d_lat, d_lng);

    if (total_distance <= 0.03)
      return console.log("you have reached...", total_distance);

    let distance = caculateDistance(m1_lat, m1_lng, m2_lat, m2_lng);
    console.log("refreshing map...distance : ", distance);
    if (distance > 0.02) {
      var current_position = [m1_lat, m1_lng];
      var new_postion = [m2_lat, m2_lng];
      this.setCar(p, new google.maps.LatLng(m2_lat, m2_lng));
      moveMarker(new_postion, this.car, current_position);
    }

    if (destinationUpdated) {
      this.dest.setPosition(new google.maps.LatLng(d_lat, d_lng));
    }
    this.calculateAndDisplayRoute(origin, destination);
  };

  setCar = (point1, point2) => {
    var rotation = google.maps.geometry.spherical.computeHeading(
      point1,
      point2
    );
    let newIcon = {
      // path: car,
      // fillColor: "#fdb031",
      // fillOpacity: 1,
      // strokeWeight: 2,
      // strokeColor: "#f6882b",
      // scale: 0.03,
      // rotation,
      // anchor: new google.maps.Point(500, 500),
      url: car_url,
      scaledSize: new window.google.maps.Size(50, 50),
      anchor: { x: 20, y: 20 },
    };

    this.car.setIcon(newIcon);
    this.map.panTo(point2);
    const marker = document.querySelector(`[src="${car_url}"]`);
    if (marker) {
      // when it hasn't loaded, it's null
      marker.style.transform = `rotate(${rotation - 90}deg)`;
      // marker.style.background = `red`;
    }
    // var bounds = new google.maps.LatLngBounds();
    // bounds.extend(this.car.getPosition());
    // this.map.fitBounds(bounds, 200);
  };

  calculateAndDisplayRoute(origin, destination) {
    console.log("origin, destination  : ", origin, destination);
    this.directionsService.route(
      {
        destination,
        origin,
        travelMode: "DRIVING",
      },
      (response, status) => {
        if (status === "OK") {
          this.directionsRenderer.setDirections(response);
          let point1 = this.car.getPosition();
          let point2 = response.routes[0].legs[0].steps[0].end_point;
          this.setCar(point1, point2);

          if (this.timer) {
            this.timer.changePosition(
              point1,
              response.routes[0].legs[0].duration.text
            );
          } else {
            this.timer = new Overlay(
              point1,
              this.map,
              response.routes[0].legs[0].duration.text
            );
          }

          console.log("directions : ", response);
        } else {
          //window.alert("Directions request failed due to " + status);
        }
      }
    );
  }

  dumyTracking = (legs) => {
    var i = 0;
    var that = this;
    var m = setInterval(() => {
      if (!legs[i]) return clearInterval(m);
      var p = legs[i].end_point;
      var result = [p.lat(), p.lng()];
      that.refresh(result.toString());
      i++;
    }, 3000);
  };

  render = () => {
    return (
      <div
        id="map"
        className="map-container"
        style={{
          width: "100vw",
          height: "100vh",
        }}
      >
        <TimeTracker />
      </div>
    );
  };
}
