import gql from "graphql-tag";

export const CANCEL_RIDE = gql`
  mutation($id: ID!) {
    cancelRide(id: $id) {
      status
    }
  }
`;

export const CHANGE_DATE_TIME = gql`
  mutation($id: ID!, $pickupDate: String!, $pickupDateForText: String!) {
    updateDateAndTime(
      id: $id
      pickupDate: $pickupDate
      pickupDateForText: $pickupDateForText
    ) {
      id
      pickupDate
      pickupDateForText
    }
  }
`;
